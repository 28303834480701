<template>
  <div>
    <div class="mobileNav">Payment</div>
    <b-container>
      <b-row class="p-3">
        <b-col md="6" sm="12" class="p-4">
          <h3 class="mb-4">MAKE A PAYMENT</h3>
          <b-row>
            <b-col sm="12" md="8">
              <div class="bg-blue text-white p-3 mycard">
                <h4 class="m-2">{{ aboutToTopup ? "Topup Fee" : "Outstanding Balance" }}</h4>
                <h2 class="m-2">$ {{ balance }}</h2>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="8">
              <div
                class="d-flex flex-row justify-content-between align-items-center p-3 bg-white mycard my-4"
              >
                <h5 class="m-0">Amount:</h5>
                <b-input
                  class="m-0 text-right"
                  v-model="amount"
                  style="width: 40%"
                  inputmode="decimal" onfocus="select()"
                ></b-input>
              </div>
              <p class="px-2">
                You can choose the amount you want to pay and recharge your
                Account Balance.
              </p>
            </b-col>
          </b-row>

          <p class="p-2 mt-2 mb-0 w-500">
            Please choose your payment method:
          </p>

          <!-- credit card  -->
          <b-row>
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('creditcard')"
              >
                <i class="fas fa-credit-card"></i>
                <p class="m-0">Credit Card</p>
              </div>
            </b-col>
          </b-row>

          <!-- pay pal  -->
          <b-row>
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('paypal')"
              >
                <i class="fab fa-paypal"></i>
                <p class="m-0">PayPal</p>
              </div>
            </b-col>
          </b-row>

          <!-- ali pay  -->
          <b-row>
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('alipay_qr')"
              >
                <i class="fab fa-alipay"></i>
                <p class="m-0">AliPay</p>
              </div>
            </b-col>
          </b-row>

          <!-- we chat pay  -->
          <b-row>
            <b-col md="8" sm="12" class="my-2">
              <div
                class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                @click="handlePayment('wx_native')"
              >
                <i class="fab fa-weixin"></i>
                <p class="m-0">WeChat Pay</p>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6" sm="12" class="p-4">
          <p class="w-500">Billing:</p>
          <p>
            Our Billing (Monthly) Cycle is from every 2nd of the month to the
            1st of the following month & our Payment period is every 15th to
            25th. This means that you receive your invoice reminder on the 15th
            of every month and you must pay before the 25th.
          </p>
          <p>
            All Overages for you current month will be on the Bill after your
            next one. There is essentially a 1 month "lag" or "delay" for
            overages. There is a charge of 5% added onto your account balance if
            you fail to pay before the 25th.
          </p>
          <p class="w-500 mt-5">Payment Options:</p>
          <p>At PhoneBox there are a few ways you can make a payment:</p>
          <ul>
            <li>
              At any one of our PhoneBox locations where we accept cash,
              cheques, debit cards, and credit cards.
            </li>
            <li>
              Pay at your bank (TD, RBC, KEB, BMO, CIBC, SCOTIABANK or CREDIT
              UNIONS only). You can even pay your PhoneBox bill on your banks
              website.
            </li>
            <li>
              Pay here on our website by logging into your Online PhoneBox
              Account with your credit card.
            </li>
            <li>
              Setup automatic withdrawals from your bank account. Please contact
              Customer Care Team to receive PAD form and send it out to us via
              email.
            </li>
          </ul>
          <p class="w-500">
            We accept almost every credit card including international credit
            cards.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <el-dialog
      :visible.sync="dialogVisible"
      :width="isMobileOrTablet ? '90%' : '30%'"
    >
      <div class="text-center">
        <i
          style="font-size: 60px"
          :style="isAlipay ? 'color:#31A3EA' : 'color:#289B37'"
          class="fab fa-alipay"
          :class="isAlipay ? 'fa-alipay' : 'fa-weixin'"
        ></i>
        <h3 class="my-2">$ {{ amount }} CAD</h3>
        <p class="my-2">
          Please scan the QR code to finish payment using your
          {{ isAlipay ? "Alipay" : "WeChat" }} App
        </p>
        <div>
          <b-img :src="qrCodeUrl"></b-img>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Payment",
  data() {
    return {
      dialogVisible: false,
      chinesePaymentMethod: "",
      isMobileOrTablet: false,
      gintTimerHandle: 0,
      gstrPayId: "",
      qrCodeUrl: "",
      amount: 0,
      balance: 0,
    };
  },
  computed: {
    isAlipay() {
      return this.chinesePaymentMethod[0] === "a";
    },
    aboutToTopup() {
      return this.dataAmountForTopup && this.balanceForTopup;
    },
    dataAmountForTopup() {
      if (this.$store.state.postpaidServiceData.tp_capacity) {
        return this.$store.state.postpaidServiceData.tp_capacity
      } else {
        return null;
      }
    },
    balanceForTopup() {
      // prepaid service data general format
      if (this.$store.state.postpaidServiceData.amount) {
        return this.$store.state.postpaidServiceData.amount
      } else {
        return null;
      }
    },
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        clearInterval(this.gintTimerHandle);
      }
    },
  },
  methods: {
    getAccountInfo() {
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          if (this.balanceForTopup) {
            this.balance = this.balanceForTopup;
          } else {
            this.balance = data.CurrentBalance;
          }
          this.amount = this.balance;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    processApiUrl(str) {
      let newStr = "";
      for (let chr of str) {
        let newChr = chr;
        if (newChr === ":") {
          newChr = "%3A";
        } else if (chr === "/") {
          newChr = "%2F";
        }
        newStr += newChr;
      }
      return newStr;
    },
    checkStatus() {
      if (this.gstrPayId) {
        this.$axios
          .get("Billing/GetIotPayStatus?payId=" + this.gstrPayId)
          .then((response) => {
            if (response.data == "Approved") {
              // this.getAccountInfo();
              this.dialogVisible = false;
              this.gstrPayId = "";
              clearInterval(this.gintTimerHandle);
              this.$notify({
                title: "Success",
                message: "Thank you for your payment !",
                type: "success",
                duration: 0,
                onClose: () => {
                  this.$router.go();
                },
              });
              setTimeout(() => {
                this.$router.push("/home");
              }, 5000);
            }
          })
          .catch(() => {
            clearInterval(this.gintTimerHandle);
          });
      }
    },
    handlePayment(value) {
      if (isNaN(this.amount)) {
        this.$notify.error({
          title: "Error!",
          message: "Please input a number for your amount!",
        });
      } else if (this.amount < 0.01) {
        this.$notify.error({
          title: "Error!",
          message: "The minimum payment amount is $0.01!",
        });
      } else if (this.amount > 300) {
        this.$notify.error({
          title: "Error!",
          message: "The maximum payment amount is $300!"
        });
      } else {
        let amountNumber = Number(this.amount);
        if (!Number.isInteger(amountNumber)) {
          this.amount = amountNumber.toFixed(2);
        }
        this.$store.commit("setLoadingTrue");
        let currentUrl = window.location.href;
        let homeUrl = currentUrl.substring(0, currentUrl.length - 7) + "home";
        let type = value;
        if (this.isMobileOrTablet && type === "alipay_qr") {
          type = "alipay_wap";
        }
        let url =
          "?amount=" +
          this.amount +
          "&billtype=" +
          type +
          "&return_url=" +
          this.processApiUrl(homeUrl) +
          "&cancel_url=" +
          this.processApiUrl(currentUrl) +
          "&systag=acctarea";
        if (this.aboutToTopup) {
          let MBforTopup = this.dataAmountForTopup * 1024;
          url += `&topupMB=${MBforTopup}`;
          console.log(url);
        }
        this.$axios
          .post("Account/CustomerBilling" + url)
          .then((response) => {
            this.$store.commit("setLoadingFalse");
            if (response.data.substring(0, 6) === "Failed") {
              this.$notify.error({
                title: "Error !",
                message: "Failed to pay your balance !",
              });
            } else {
              let redirectUrl = response.data;
              if (type === "alipay_qr" || type === "wx_native") {
                this.chinesePaymentMethod = type;
                let data = redirectUrl.split("^");
                if (data.length == 2) {
                  this.gstrPayId = data[0];
                  // console.log("image gstrPayId=" + this.gstrPayId);
                  this.qrCodeUrl = data[1];
                  this.gintTimerHandle = setInterval(this.checkStatus, 1000);
                } else {
                  console.log("image wrong format");
                }
                this.dialogVisible = true;
              } else {
                location.href = redirectUrl;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setLoadingFalse");
            this.$notify.error({
              title: "Error !",
              message: "Payment Error !",
            });
          });
      }
    },
  },
  mounted() {
    this.getAccountInfo();
    this.isMobileOrTablet = window.innerWidth <= 1024;

    if (this.aboutToTopup) {
      console.log("you are about to top up!!!!!")
    } else {
      console.log("you are NOT about to top up...");
    }
  },
};
</script>

<style lang="scss" scoped>
.creditCard {
  cursor: pointer;
}
</style>
